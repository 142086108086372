<template>
  <!-- <a-spin size="large" :spinning="loading"> -->
  <div class="home">
    <div>
      <a-select
        class="select"
        style="width: 440px; margin-right: 5px"
        size="large"
        :class="[lang]"
        :placeholder="$t('inputLinkAccount')"
        @change="handleChange"
        v-model="linkedin_email"
      >
        <a-select-option :class="[lang]" value="">{{
          $t("inputLinkAccount")
        }}</a-select-option>
        <a-select-option
          :class="[lang]"
          v-for="(item, index) in linkList"
          :key="index"
          :value="item.linkedin_id"
        >
          {{ item.linkedin_email }}
        </a-select-option>
        <!-- <a-select-option value="new">+ A New LinkedIn Account</a-select-option> -->
      </a-select>
      <a-button
        type="primary"
        :class="[lang]"
        @click="createCampaign"
        size="large"
      >
        {{ $t("Create") }}
      </a-button>
    </div>
    <div class="wrap-table" style="min-height: 650px">
      <ul class="nav">
        <li
          class="left"
          @click="tabHandler('left')"
          :class="{ active: leftActive }"
        >
          <span :class="[lang]">{{ $t("Active") }}</span>
        </li>
        <li
          class="right"
          @click="tabHandler('right')"
          :class="{ active: rightActive }"
        >
          <span :class="[lang]">
            {{ $t("Paused") }}
          </span>
        </li>
      </ul>
      <a-table
        :scroll="{
          x: 1120,
        }"
        :class="{ arTable: lang == 'AR' ? true : false }"
        class="custom-scrollbar capinTable"
        size="middle"
        :columns="columns"
        :rowKey="(record, index) => index"
        :data-source="tableData"
        :pagination="false"
        :loading="loading"
        :locale="{ emptyText: $t('noCamp') }"
        :row-class-name="
          (_record, index) => (index % 2 !== 1 ? 'table-bg' : null)
        "
        :customRow="
          (record) => {
            return {
              on: {
                click: () => goDetail(record),
              },
            };
          }
        "
      >
        <template slot="status" slot-scope="text, record" class="arial">
          <div @click.stop="">
            <a-switch
              @click.stop=""
              size="small"
              v-model="record.status"
              @change="onChangeRecord(record)"
            />
          </div>
        </template>
         <template slot="campaign_name" slot-scope="text, record">
            {{ record.campaign_name }}
            <CampaignTableTip
              :record="record.action_log"
              v-if="record && record.action_log"
            />
          </template>

        <template slot="action" slot-scope="text, record" class="arial">
          <a-dropdown>
            <template #overlay>
              <a-menu>
                <a-menu-item key="1" @click="goDetail(record)">
                  <a-icon type="edit" />
                  <span :class="[lang]">{{ $t("Details") }}</span>
                </a-menu-item>
                <a-menu-item key="2" @click="copyHandler(record)">
                  <a-icon type="copy" />
                  <span :class="[lang]">{{ $t("Copy") }}</span>
                </a-menu-item>
                <a-menu-item key="3" @click="deleteHandler(record)">
                  <a-icon type="delete" />
                  <span :class="[lang]">{{ $t("Delete") }}</span>
                </a-menu-item>
              </a-menu>
            </template>
            <a-button style="padding: 0 8px" @click.stop="">
              <span :class="[lang]">{{ $t("Action") }}</span>
              <a-icon type="down" />
            </a-button>
          </a-dropdown>
        </template>
      </a-table>
      <div>
        <app-footer
          :current="page"
          :pageSize="pageSize"
          :total="total"
          @onPageChange="onPageChange"
          @onPageSizeChange="onPageSizeChange"
        ></app-footer>
      </div>
    </div>
  </div>
  <!-- </a-spin> -->
</template>

<script>
import BaseComponent from "@/components/base.component";
import CampaignTableTip from './component/campaignTableTip.vue';

export default {
  components: {
    CampaignTableTip
  },
  data() {
    return {
      loading: false,
      leftActive: true,
      rightActive: false,
      tableData: [],
      linkList: [],
      linkedin_email: "",
    };
  },
  i18n: require("./i18n"),
  mixins: [BaseComponent],
  computed: {
    columns: {
      get() {
        let that = this;
        return [
          {
            title: that.$t("Name"),
            width: 200,
            dataIndex: "campaign_name",
            // align: "center",
            ellipsis: true,
            // fixed: "left",
            scopedSlots: {
              customRender: "campaign_name",
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("Status"),
            width: 160,
            dataIndex: "status",
            align: "center",
            // fixed: "left",
            scopedSlots: {
              customRender: "status",
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("LinkedinlD"),
            width: 200,
            dataIndex: "linkedin_email",
            ellipsis: true,
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("Days"),
            width: 120,
            dataIndex: "days",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("TotalNumber"),
            width: that.lang == "US" ? 260 : that.lang == "AR" ? 200 : 120,
            dataIndex: "customer_find",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("ConnectionsRequest"),
            width: that.lang == "US" ? 200 : that.lang == "AR" ? 200 : 120,
            dataIndex: "connections_request",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("ConnectionsMade"),
            width: that.lang == "US" ? 200 : that.lang == "AR" ? 200 : 120,
            dataIndex: "connections_made",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },

          {
            title: that.$t("Acceptedrate"),
            width: 120,
            dataIndex: "accept_rate",
            align: "center",
            customRender: function (text) {
              return text ? text + "%" : "0%";
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("NumberOfGreeters"),
            width: that.lang == "US" ? 200 : that.lang == "AR" ? 200 : 120,
            dataIndex: "greeting_count",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },

          {
            title: that.$t("Replies"),
            width: 120,
            dataIndex: "replies",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("Repliesrate"),
            width: that.lang == "US" ? 120 : that.lang == "AR" ? 200 : 120,
            dataIndex: "replies_rate",
            align: "center",
            customRender: function (text) {
              return text ? text + "%" : "0%";
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("Message"),
            width: 120,
            dataIndex: "message",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("LeadsGenerated"),
            width: that.lang == "US" ? 120 : that.lang == "AR" ? 200 : 120,
            dataIndex: "leads",
            align: "center",
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
          {
            title: that.$t("ConversionRate"),
            width: 120,
            dataIndex: "rate",
            align: "center",
            customRender: function (text) {
              return text ? text + "%" : "0%";
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },

          {
            title: that.$t("Action"),
            align: "center",
            fixed: "right",
            width: 120,
            scopedSlots: {
              customRender: "action",
            },
            customCell: (record) => {
              return that.getCustomCell(record)
            }
          },
        ];
      },
    },
  },
  created() {},
  activated() {
    this.initData();
  },
  methods: {
    initData() {
      this.form.resetFields();
      this.linkedin_email = "";
      this.getLinkList();
      if (this.$route.query.isHave === "1") {
        this.leftActive = false;
        this.rightActive = true;
      } else if (this.$route.query.isHave === "0") {
        this.leftActive = true;
        this.rightActive = false;
      }
      this.getData(this.$route.query.isHave);
    },
    handleChange() {
      this.page = 1;
      this.getData();
    },

    getLinkList() {
      this.$axios(
        "/linkedin/list",
        { campaign_type: 2, status: 1 },
        "post"
      ).then((res) => {
        if (res.code == 200) {
          this.linkList = res.data;
        }
      });
    },
    deleteHandler(record) {
      let that = this;
      this.$confirm({
        title: that.$t("confirmDel") + "?",
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          that
            .$axios(
              "/recruiter/campaign_delete",
              { campaign_id: record.id },
              "post"
            )
            .then((res) => {
              if (res.code == 200) {
                if (that.tableData.length == 1 && that.page > 1) {
                  that.page--;
                  that.getData();
                } else {
                  that.getData();
                }
              }
            });
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    copyHandler(record) {
      let { id } = record;
      this.$router.push({
        path: "/hr/recruiter/createCampaign",
        query: { campaign_id: id, edit: 1 },
      });
    },
    goDetail(record) {
      let { id, status } = record;
      this.$router.push({
        path: "/hr/recruiter/campaignDetail",
        query: { campaign_id: id, customer_status: status ? 1 : 0 },
      });
    },
    createCampaign() {
      this.$router.push({
        path: "/hr/recruiter/createCampaign",
        query: { linkedin_id: "new" },
      });
      // this.$router.push({
      //   path: "/hr/recruiter/createCampaign",
      //   // query: params,
      // });
      // if (!this.linkedin_email) {
      //   this.$router.push({
      //     path: "/hr/recruiter/createCampaign",
      //     query: { linkedin_id: "new" },
      //   });
      //   return;
      // }
      // let getHave = null;
      // this.linkList.some((item) => {
      //   if (item.linkedin_id == this.linkedin_email) {
      //     getHave = item;
      //     return true;
      //   } else {
      //     return false;
      //   }
      // });
      // let params = { linkedin_id: this.linkedin_email };
      // if (getHave) {
      //   params = getHave;
      // }
      // this.$router.push({
      //   path: "/hr/recruiter/createCampaign",
      //   query: params,
      // });
    },
    getData(isHave) {
      let status = this.leftActive ? 1 : 0;

      if (isHave === "1") {
        status = 0;
      } else if (isHave === "0") {
        status = 1;
      }
      let postData = {
        page: this.page,
        page_size: this.pageSize,
        linkedin_id: this.linkedin_email ? parseInt(this.linkedin_email) : null,
        campaign_status: status,
      };
      if (
        this.linkedin_email !== "new" &&
        this.linkedin_email !== "selectedDaata"
      ) {
        this.linkedin_id = this.linkedin_email;
      }
      this.loading = true;
      this.$axios("/recruiter/campaign_list", postData, "post")
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            let { list, total_num, page_size } = res.data || {};
            this.detailData = res.data;
            let arr = [];
            list.forEach((item) => {
              item.status = item.status == 0 ? false : true;
              arr.push(item);
            });
            this.tableData = list;
            this.total = total_num;
            this.pageCount = Math.ceil(total_num / page_size);
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    tabHandler(type) {
      if (type == "left") {
        this.leftActive = true;
        this.rightActive = false;
      } else {
        this.leftActive = false;
        this.rightActive = true;
      }
      this.page = 1;
      this.getData();
    },
    onChangeRecord(record) {
      let { id, status } = record;
      this.$axios(
        "/recruiter/campaign_change_status",
        { campaign_id: id, change_status: status ? 1 : 0 },
        "post"
      ).then((res) => {
        let { code, data } = res;
        let that = this;
        if (code == 200) {
          if (data.result == 1 && this.tableData.length == 1 && this.page > 1) {
            this.page--;
            this.getData();
          } else if (data.result == 1) {
            this.getData();
          } else if (data.result == 2) {
            //this.getData()
            record.status = !status;
            this.$warning({
              content: this.$t("statusChangeTip"),
              okText: this.$t("goSet"),
              onOk() {
                that.leftActive = true;
                that.rightActive = false;
                that.page = 1;
                that.getData();
              },
            });
          }

          //this.dashBorderData = res.data;
        } else {
          record.status = !status;
        }
      });
      console.log(record, arguments);
    },
    handleMenuClick(val) {
      console.log(val, arguments);
    },
    getCustomCell (record) {
      if (record.action_log && record.action_log.action_log && record.action_log.action_log.length > 0) {
        return {
          style: {
            'padding-bottom': '66px',
          }
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
.home {
  margin-top: 50px;
  padding: 20px 30px;
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .wrap-table {
    padding: 0px 20px 12px 20px;
    background: #fff;
    border-radius: 4px;
    margin-top: 20px;
  }
  /deep/.ant-table-row-hover {
    background: rgba(238, 248, 247, 1) !important;
  }
  /deep/.ant-btn {
    background: #0c6158;
    color: #fff;
  }
  .nav {
    padding-top: 20px;
    border-radius: 2px;
    margin-bottom: 15px;
    li {
      // width: 86px;
      height: 28px;
      padding: 0 20px;
      display: inline-block;
      text-align: center;
      line-height: 28px;
      cursor: pointer;
    }
    .left {
      border-radius: 2px 0px 0px 2px;
      border: 1px solid #d6d6d6;
      border-right: none;
    }

    .right {
      border-radius: 0px 2px 2px 0px;
      border: 1px solid #d6d6d6;
      border-left: none;
    }
    .active {
      color: #fff;
      border: 1px solid #0e756a;
      background: #0e756a;
    }
  }
  .capinTable {
    /deep/ tr.ant-table-row {
      position: relative;
      // td {
      //   padding-bottom: 66px !important;
      // }
    }
  }
}
/deep/.ant-table-row {
  cursor: pointer;
}
</style>
