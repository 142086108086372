import { render, staticRenderFns } from "./campaign.vue?vue&type=template&id=766e41f6&scoped=true&"
import script from "./campaign.vue?vue&type=script&lang=js&"
export * from "./campaign.vue?vue&type=script&lang=js&"
import style0 from "./campaign.vue?vue&type=style&index=0&id=766e41f6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "766e41f6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/saas_fe_dev_agent/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('766e41f6')) {
      api.createRecord('766e41f6', component.options)
    } else {
      api.reload('766e41f6', component.options)
    }
    module.hot.accept("./campaign.vue?vue&type=template&id=766e41f6&scoped=true&", function () {
      api.rerender('766e41f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/hr/recruiter/campaign.vue"
export default component.exports